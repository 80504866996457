import { getSettings } from "./Settings";
import clientTranslate from "./clientTranslate";

interface TransRes {
  success: boolean;
  code: number;
  errMsg?: string;
  targetText?: string;
}

interface DictionaryEntry {
  word: string;
  sw: string;
  definition: string;
  translation: string;
  pos: string;
  exchange: string;
  examples: string;
  phonetic: string;
}

class Translator {
  apiKey: string;
  apiBack: string;

  constructor(apiKey: string, apiBack: string = "/api") {
    this.apiKey = apiKey;
    this.apiBack = apiBack;
  }

  async lookup(word: string): Promise<DictionaryEntry | []> {
    try {
      const response = await fetch(`${this.apiBack}/dictionary?word=${word}`);

      const data: DictionaryEntry[] = await response.json();

      if (response.ok) {
        return data[0];
      } else {
        return [];
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error("An unknown error occurred");
    }
  }

  async translate(text: string) {
    const settings = getSettings();
    try {
      if (settings.translator.service === "kiwi-local")
        return await clientTranslate(text);
      const response = await fetch(`${this.apiBack}/translate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          service: settings.translator.service,
          sourceLang: settings.translator.sourceLang,
          targetLang: settings.translator.targetLang,
          sourceText: text,
        }),
      });

      const data: TransRes = await response.json();

      if (data.success && response.ok) {
        return data.targetText;
      } else {
        throw new Error(data.errMsg);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error("An unknown error occurred");
    }
  }
}

export default Translator;
