import React, { useRef } from "react";
import { IconUpload } from "@douyinfe/semi-icons";
import { Button } from "@douyinfe/semi-ui";
import PropTypes from "prop-types";
import { handleFileChange, handleFile } from "./utils/importFile";

export function FileUpload({ onUpdate }) {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  handleFile(onUpdate);

  return (
    <div>
      <input
        accept="application/pdf"
        multiple
        type="file"
        ref={fileInputRef}
        onChange={() => {
          handleFileChange(event, onUpdate, false);
        }}
        style={{ display: "none" }}
      />
      <Button
        icon={<IconUpload />}
        aria-label="上传"
        onClick={handleButtonClick}
      >
        上传文档
      </Button>
    </div>
  );
}

FileUpload.propTypes = {
  onUpdate: PropTypes.func.isRequired,
};
