 
import React, { useEffect, useState } from "react";
import {
  List,
  Avatar,
  ButtonGroup,
  Button,
  Notification,
  Popconfirm,
} from "@douyinfe/semi-ui";
import { Link } from "react-router-dom";

import { getAllFileMetadata, deleteFile } from "./utils/StorageFS";

const ShowFiles = (refresh) => {
  const [data, setData] = useState();
  const [r, reload] = useState();
  const style = {
    height: "80px",
    border: "1px solid var(--semi-color-border)",
    backgroundColor: "var(--semi-color-bg-2)",
    borderRadius: "6px",
    paddingLeft: "20px",
  };
  useEffect(() => {
    getAllFileMetadata()
      .then((metadata) => {
        setData(metadata);
      })
      .catch((error) => {
        setData({});
        console.error("无法获取文件元数据:", error);
      });
  }, [refresh, r]);

  return (
    <div className="files">
      <List
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            style={style}
            header={
              <Link
                style={{ textDecoration: "none" }}
                to={"files" + item.path + item.fileName}
              >
                <Avatar color={item.color}>
                  {item.fileName.substring(0, 2).toUpperCase()}
                </Avatar>
              </Link>
            }
            main={
              <div>
                <Link
                  style={{ textDecoration: "none" }}
                  to={"files" + item.path + item.fileName}
                >
                  <span
                    style={{
                      color: "var(--semi-color-text-0)",
                      fontWeight: 500,
                    }}
                  >
                    {item.fileName}
                  </span>
                </Link>
                <p
                  style={{
                    color: "var(--semi-color-text-2)",
                    fontWeight: 400,
                    margin: "4px 0",
                  }}
                >
                  {item.size}
                </p>
              </div>
            }
            extra={
              <ButtonGroup theme="borderless">
                <Button
                  theme="borderless"
                  className="lastModifyDate"
                  style={{ color: "var(--semi-color-common)" }}
                >
                  {item.date}
                </Button>
                <Link
                  style={{ textDecoration: "none" }}
                  to={"files" + item.path + item.fileName}
                >
                  <Button theme="borderless">查看</Button>
                </Link>
                <Popconfirm
                  title="确认"
                  content="是否确认删除此文件"
                  onConfirm={() => {
                    reload(deleteFile(item.id));
                    Notification.success({
                      title: "文件已删除",
                    });
                  }}
                >
                  <Button style={{ color: "var(--semi-color-danger)" }}>
                    删除
                  </Button>
                </Popconfirm>
              </ButtonGroup>
            }
          />
        )}
      />
    </div>
  );
};

export default ShowFiles;
