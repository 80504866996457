import React from "react";
import { Empty } from "@douyinfe/semi-ui";
import {
  IllustrationIdle,
  IllustrationIdleDark,
} from "@douyinfe/semi-illustrations";
import { useDocumentTitle } from "@uidotdev/usehooks";
import PropTypes from "prop-types";

const InternalError = (props) => {
  useDocumentTitle("喔唷，崩溃啦！ - Kiwi PDF Reader");
  return (
    <Empty
      image={<IllustrationIdle style={{ width: 200, height: 200 }} />}
      darkModeImage={
        <IllustrationIdleDark style={{ width: 200, height: 200 }} />
      }
      title={"出错了"}
      description={
        <>
          <p>当前页面出现问题 / 错误信息：{props.errorInfo}</p>
          <p>
            您可以尝试
            <a href="/" style={{ textDecoration: "none" }}>
              回到首页
            </a>
            或
            <a href="" style={{ textDecoration: "none" }}>
              刷新页面
            </a>
          </p>
        </>
      }
    />
  );
};

InternalError.propTypes = {
  errorInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
};

export default InternalError;
