import React from "react";
import PropTypes from "prop-types";
import InternalError from "./pages/internalError";
// import { Banner } from "@douyinfe/semi-ui";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // 更新 state 使下一次渲染能够显示降级后的 UI
    return { hasError: true, error: error.toString() };
  }

  componentDidCatch(error, errorInfo) {
    // 你同样可以将错误日志上报给服务器
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // 你可以自定义降级后的 UI 并渲染
      //   return <InternalError errorInfo={this.state.error}></InternalError>;

      return (
        <>
          {/* <Banner
            type="danger"
            description={"页面出错了 " + this.state.error}
          />
          {this.props.children} */}

          <InternalError errorInfo={this.state.error}></InternalError>
        </>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};
