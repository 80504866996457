 
import React from "react";
import { Empty } from "@douyinfe/semi-ui";
import {
  IllustrationConstruction,
  IllustrationConstructionDark,
} from "@douyinfe/semi-illustrations";
import { useDocumentTitle } from "@uidotdev/usehooks";
import { Link } from "react-router-dom";

const RouteNotFound = () => {
  useDocumentTitle("功能建设中 - Kiwi PDF Reader");
  return (
    <Empty
      image={<IllustrationConstruction style={{ width: 200, height: 200 }} />}
      darkModeImage={
        <IllustrationConstructionDark style={{ width: 200, height: 200 }} />
      }
      title={"功能建设中"}
      description={
        <>
          <p>当前功能暂未开放，敬请期待</p>
          <br />
          <p>
            您可以由此
            <Link to="/" style={{ textDecoration: "none" }}>
              回到首页
            </Link>
          </p>
        </>
      }
    />
  );
};

export default RouteNotFound;
